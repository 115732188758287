import React from 'react';
import { useModal, Modal } from 'react-morphing-modal';
import 'react-morphing-modal/dist/ReactMorphingModal.css';

const MorphingModal = ({description}) => {
  const { modalProps, getTriggerProps } = useModal();

  return (
    <div>
      <button {...getTriggerProps()} className='btn'>Read more</button>
      <div className='modal-text'>
        <Modal {...modalProps}>{description}</Modal>
      </div>
    </div>
  );
}

export default MorphingModal;