import React from 'react';
import { useRef } from 'react';
import emailjs from 'emailjs-com';

import './contact.css';

const Contact = () => {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_lr81qz5', 'template_p3xh91q', form.current, 'kuX-GVOuMftQK518R')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

    e.target.reset()
  };

  return (
    <section id='contact'>
      <div className="container contact__container">
        <h2>Get In Touch</h2>
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name="name" placeholder='Your Full Name' required />
          <input type="email" name="email" placeholder='Your Email' required />    
          <textarea name="message" rows="7" placeholder='Your Message' required ></textarea>         
          <button type="submit" className='btn btn-primary'>Submit</button>
        </form>
      </div>
    </section>
  )
}

export default Contact