import React from 'react'
import './header.css'
import CTA from './CTA'
import ME from '../../assets/portfolio_me_2.png'
import HeaderSocials from './HeaderSocials'

const Header = () => {
  return (
    <header>
        <div className="container header__container">
          <HeaderSocials />
          <div className='header__text'>
            <h3>Hi there!👋</h3>
            <h1>I'm Pranav</h1>
            <p>A <b>Full Stack Software Engineer</b> 👨‍💻 with 4 years of experience in building scalable and efficient systems that can handle high traffic and high availability. Currently pursuing a Master's degree at the <b>University of Florida</b> with a keen interest in Machine Learning. </p>
            {/* <h5 className='text-dark'>Fullstack Software Engineer 👨‍💻</h5> */}
            <CTA />
          </div>
          {/* <div className='me'>
                <img src={ME} alt="me" />
          </div> */}
          <a href='mailto:manglanipranav@gmail.com' className='scroll__down'>manglanipranav@gmail.com</a>
        </div>
    </header>
  )
}

export default Header