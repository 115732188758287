import React from 'react'

import MorphingModal from "../modal/MorphingModal";

import './experience.css'

import IMG_BUS from '../../assets/bus_project.jpg'
import IMG_LKL from '../../assets/IMG_LKL.jpg'
import IMG_DB from '../../assets/IMG_DB.png'
import IMG_AI4G from '../../assets/IMG_AI4G.jpg'
import IMG_FNX from '../../assets/IMG_FNX.png'
import IMG_GAN from '../../assets/IMG_GAN.jpg'
import IMG_GML from '../../assets/IMG_GML.png'

const data = [
  {
    id: 7,
    image: IMG_GML,
    title: 'Gramhal: Digital solutions for agricultural communities',
    read_more: 'Gramhal: Non-profit that designs digital solutions for agricultural communities to enable them to make better decisions\n\n'
    + 'Technology: Flask, AWS (EC2, RDS, Lambda), PostgreSQL, HTML, CSS, JS, Chabot Development\n\n'
    + 'Contributions / Responsibilties: \n\n'
    + '•	Led the design and development of the backend infrastructure for Bolbhav Plus: a product accessible via WhatsApp chatbot where farmers can view real-time crowdsourced prices of crops being sold at wholesale markets. This product reached 14,000+ unique farmers in 2 weeks.\n\n'
    + '•	Created a full-stack solution (website and APIs) to capture the farmers’ device’s location and reverse-geocode it to fetch details of the state, district, community block, and village to give location-specific advisory. This reduced onboarding time and user drop-off rate and improved the user experience for the farmer.\n\n'
    + '•	Designed and implemented database models and 5+ APIs to allow farmers to avail of Subscription Plans as part of Bolbhav Plus and track the validity of these subscriptions.\n\n'
    + '•	Collaborated with officials of District Administration of Mansa, Punjab, to launch a new chatbot - \'Mansa Krishi Sevak\' - to introduce crop and weather advisory in Gurmukhi language. The new chatbot scaled to 7000+ users within 2 weeks after launch.\n'
    ,
    demo: 'https://gramhal.org/'
  },
  {
    id: 1,
    image: IMG_LKL,
    title: 'LokalApp: Hyperlocal content and services in regional languages',
    read_more: 'LokalApp: Hyperlocal content and services platform catering to users that communicate in vernacular languages\n\n'
    + 'Technology: Django, AWS (EC2, RDS, Lambda), PostgreSQL, Redis, Elasticsearch, Kafka, RabbitMQ\n\n'
    + 'Contributions / Responsibilties: \n\n'
    + '•	Redesigned and developed the most used, ‘News Feed API.’ Reduced the load on database by setting up Elasticsearch as primary source for filtering news. Achieved 80% reduction in latency with response times (P99) of less than 50 milliseconds. Repurposed Elasticsearch setup in ‘User Search’, reducing latency by 95%.\n\n'
    + '•	Led the design and implementation of a Referral System to drive user installs for the app. This included Database modeling, API development, UI implementation, Payments integration, and Analytics tracking. This attracted 11000+ new users within the first week of the feature release.\n\n'
    + '•	Developed 10+ highly available, high throughput, and low latency APIs to make UI and navigation in our application configurable from the backend. Set up an A/B test framework to put 10 million active users in different variants and gradually roll out the feature. This resulted in a 7% increase in user retention.\n'
    ,
    demo: 'https://getlokalapp.com/'
  },
  {
    id: 2,
    image: IMG_DB,
    title: 'Deutsche Bank: Corporate Banking',
    read_more: 'Deutsche Bank: Backend Development team for the Corporate Banking Division\n\n'
    + 'Technology: Java, SpringBoot, Oracle, RabbitMQ, Kafka, Docker, Kubernetes, Jenkins\n\n'
    + 'Contributions / Responsibilties: \n\n'
    + '•	Built end-to-end scalable and fault-tolerant microservices that sent notifications to external clients like Apple, Uber, and Reliance Jio - after validation of the credit payments for the client. Developed 5+ APIs and set up CI/CD pipelines. Set up asynchronous messaging to communicate with internal and external services.\n\n'
    + '•	Developed 10+ features for the Booking and Limit application that performed complex String processing, validation, and transformation for transactions before the settlement of funds by legacy Core Banking applications. The APIs were used by 50+ branches of the bank in the Asia-Pacific region.\n\n'
    + '•	Led end-to-end development of an API starter kit to use across the bank. Included boiler-plate API code, in-memory database, authentication, and testing framework. It won the runner-up prize at the prestigious ‘Bank on Tech’ event, chosen by global leadership at Deutsche, and was leveraged by various teams.\n'
    ,
    demo: 'https://corporates.db.com/'
  },
  {
    id: 3,
    image: IMG_BUS,
    title: '201 Bus Project: Sound Map of Bangalore Buses',
    read_more: '201 Bus Project: Sound Map of Bangalore Buses\n\n'
    + '•	Independently developed and deployed an online soundscape that gave users a sensorial experience (via audio, written text, and an interactive city map) of the bus journeys across the city of Bangalore. This website was built using React (JavaScript) and Mapbox, under a grant provided by India Foundation for the Arts.',
    demo: 'http://bus201project.herokuapp.com/'
  },
  {
    id: 4,
    image: IMG_AI4G,
    title: 'COVID-19 Simulator: Initiative by AI4Good',
    read_more: '•	Volunteered with AI4Good to build a simulator that modelled the population in refugee camps and the spread of disease in case of an outbreak of Covid-19. Camps in Moria, Greece leveraged it to implement interventions.',
    demo: 'https://www.aiforgood.co.uk/blog/ai-for-good-simulator'
  },
  {
    id: 5,
    image: IMG_GAN,
    title: 'Text to Photo-Realistic Image Synthesis',
    read_more: 'Text to Photo-Realistic Image Synthesis\n'
    +'•	Developed an application that allowed users to generate photo-realistic images from human-written text descriptions. Researched and compared existing ML models and implemented a solution using the Generative Adversarial Networks (consisting of multiple Convolutional Neural Networks) in Python and TensorFlow.',
    demo: ''
  },
  {
    id: 6,
    image: IMG_FNX,
    title: 'Finxera: Payment processing using Cloud Computing',
    read_more: 'Finxera: Payment processing using Cloud Computing\n\n'
    + 'Contributions / Responsibilties: \n\n•	Redesigned and refactored the ‘SDN Validator’ that checked the names of the company’s clients against a list of Specially Designated Nationals (SDN) provided by OFAC, US Treasury. The changes reduced processing time by 45% by indexing the client’s database using Lucene and implementing multi-threading.',
    demo: ''
  }
]

const Experience = () => {
  return (
    <section id='experience'>
        <h2>Experience</h2>

        <div className='container portfolio__container'>
          {
            data.map(({id, image, title, read_more, demo}) => {
              console.log({demo}.demo)
              return (  
              <article key={id} className='portfolio__item'>
                <div className='portfolio__item-image'>
                  <img src={image} alt={title} />
                </div>
                <p className='portfolio__item-title'>{title}</p>
                <div className='portfolio__item-cta'>
                  {/* <a href={read_more} className='btn'>Read More</a> */}
                  <MorphingModal description={read_more} />
                  <button onClick={() => window.open({demo}.demo, '_blank')} className='btn btn-primary'>Website</button>
                </div>
              </article>
              )
            })
          }
          {/* <Popup trigger={true}>
            <h3>Popup</h3>  
          </Popup>     */}
        </div>    
    </section>
  )
}

export default Experience