import React from 'react'
import './skills.css'
import { BsPatchCheckFill } from 'react-icons/bs'

const Skills = () => {
  return (
    <section id='skills'>
        <h2>Skills</h2>
        <div className='container skills__container'>
            <div className='skills__backend'>
                <h3>Backend Development</h3>
                <div className='skills_content'>
                    <article className='skills__details'>
                        <BsPatchCheckFill className='skills__details-icon' />
                        <div>
                            <h4>Django (Python)</h4>
                            <small className='text-dark'>Proficient</small>
                        </div>
                    </article>
                    <article className='skills__details'>
                        <BsPatchCheckFill className='skills__details-icon' />
                        <div>
                            <h4>Flask (Python)</h4>
                            <small className='text-dark'>Proficient</small>
                        </div>
                    </article>
                    <article className='skills__details'>
                        <BsPatchCheckFill className='skills__details-icon' />
                        <div>
                            <h4>SpringBoot (Java)</h4>
                            <small className='text-dark'>Proficient</small>
                        </div>
                    </article>
                    <article className='skills__details'>
                        <BsPatchCheckFill className='skills__details-icon' />
                        <div>
                            <h4>C/C++</h4>
                            <small className='text-dark'>Intermediate</small>
                        </div>
                    </article>
                    <article className='skills__details'>
                        <BsPatchCheckFill className='skills__details-icon' />
                        <div>
                            <h4>PostgreSQL</h4>
                            <small className='text-dark'>Intermediate</small>
                        </div>
                    </article>
                    <article className='skills__details'>
                        <BsPatchCheckFill className='skills__details-icon' />
                        <div>
                            <h4>Elasticsearch</h4>
                            <small className='text-dark'>Proficient</small>
                        </div>
                    </article>
                    <article className='skills__details'>
                        <BsPatchCheckFill className='skills__details-icon' />
                        <div>
                            <h4>Redis</h4>
                            <small className='text-dark'>Intermediate</small>
                        </div>
                    </article>
                    <article className='skills__details'>
                        <BsPatchCheckFill className='skills__details-icon' />
                        <div>
                            <h4>Kafka</h4>
                            <small className='text-dark'>Intermediate</small>
                        </div>
                    </article>
                </div>
            </div>
            <div className='skills__frontend'>
                <h3>Frontend Development</h3>
                <div className='skills_content'>
                    <article className='skills__details'>
                        <BsPatchCheckFill className='skills__details-icon' />
                        <div>
                            <h4>HTML</h4>
                            <small className='text-dark'>Proficient</small>
                        </div>
                    </article>
                    <article className='skills__details'>
                        <BsPatchCheckFill className='skills__details-icon' />
                        <div>
                            <h4>CSS</h4>
                            <small className='text-dark'>Intermediate</small>
                        </div>
                    </article>
                    <article className='skills__details'>
                        <BsPatchCheckFill className='skills__details-icon' />
                        <div>
                            <h4>Javascript</h4>
                            <small className='text-dark'>Intermediate</small>
                        </div>
                    </article>
                    <article className='skills__details'>
                        <BsPatchCheckFill className='skills__details-icon' />
                        <div>
                            <h4>React</h4>
                            <small className='text-dark'>Intermediate</small>
                        </div>
                    </article>
                </div>
            </div>
            <div className='skills__cloud'>
                <h3>Cloud Technologies and CI/CD Tools</h3>
                <div className='skills_content'>
                    <article className='skills__details'>
                        <BsPatchCheckFill className='skills__details-icon' />
                        <div>
                            <h4>AWS</h4>
                            <small className='text-dark'>Intermediate</small>
                        </div>
                    </article>
                    <article className='skills__details'>
                        <BsPatchCheckFill className='skills__details-icon' />
                        <div>
                            <h4>Bitbucket / Jira / Git</h4>
                            <small className='text-dark'>Proficient</small>
                        </div>
                    </article>
                    <article className='skills__details'>
                        <BsPatchCheckFill className='skills__details-icon' />
                        <div>
                            <h4>Docker and Kubernetes</h4>
                            <small className='text-dark'>Intermediate</small>
                        </div>
                    </article>
                    <article className='skills__details'>
                        <BsPatchCheckFill className='skills__details-icon' />
                        <div>
                            <h4>Jenkins</h4>
                            <small className='text-dark'>Intermediate</small>
                        </div>
                    </article>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Skills