import React from 'react'
import './about.css'
import ME from '../../assets/about-me.jpg'

const About = () => {
  return (
    <section id='about'>
        {/* <h5>Get To Know</h5> */}
        <h2>About Me</h2>
        <div className='container about__container'>
            <div className='about__me'>
                <div className='about__me-image'>
                    <img src={ME} alt="About Image" />
                </div>
            </div>

            <div className='about__content'>
                {/* <div className='about__cards'>
                    <article className='about__card'>
                        <h5>Experience</h5>
                        <small>3+ Years Working</small>
                    </article>
                </div> */}
                <p> 
                👨 Mission-driven, autonomous, and collaborative technologist. <br></br>
                🔨 Passionate about architecting software infrastructure and crafting user-centric applications. <br></br>
                🌎 Delivered 10x growth at Y-Combinator startups, large corporates, small businesses, and non-profits. <br></br>
                🚀 Launched projects from scratch, owned end-to-end development, and optimized them to handle over 10 million users. <br></br>
                🤝 Always looking to add value to places where the mission and values align.             </p>
                {/* <a href="#contact" className='btn btn-primary'>Let's Talk</a> */}
            </div>
        </div>
    </section>
  )
}

export default About